import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import Layout from '~components/Layout'
import scssVars from '~components/scss-variables'
import Main from '~components/layouts/Main'
import HeroSection from '~components/layouts/marketing/HeroSection'
import SEO from '~components/seo'
import MainTestimonial from '~components/Testimonials/MainTestimonial'

const Content = styled.div`
  h3, h4, p {
    color: ${scssVars.darkBrown};
  }
  h3, h4 {
    font-weight: 600;
    line-height: 1.25;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    margin-bottom: 25px;
    a {
      color: ${scssVars.darkBrown};
      font-family: "EB Garamond", serif;
      &:hover, &:focus {
        color: ${scssVars.darkBrown};
      }
    }
  }
  ul {
    color: ${scssVars.darkBrown};
    li {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }
  }
  hr {
    margin: 20px 0 35px;
    border-color: ${scssVars.beige};
  }
`

const HighlightText = styled(MainTestimonial)`
  p {
    text-align: center;
    padding-left: 0;
    &::before, &::after {
      content: '';
    }
  }
`

const ListHeader = styled.p`
  font-size: 1rem;
  color: ${scssVars.orange};
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
`

const List = styled.ul`
  li {
      font-family: 'Montserrat', sans-serif;
      text-transform: uppercase;
      &::marker {
        color: ${scssVars.orange};
      }
    }
`

const StyledLink = styled(Link)`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    width: 50% !important;
    margin: 0 auto;
  }
`

const CovidUpdates = () => {
  return (
    <Layout>
      <SEO
        title="COVID-19 Updates"
        pathname="/covid-19-updates"
        description="We hope you’re staying safe and healthy. These are uncertain times, but we continue to be inspired by the stories of hope and perseverance from around the world"
      />
      <Main>
        <HeroSection
          title={'COVID-19 Updates'}
          mobileImg={'https://leatherspa.imgix.net/covid1.jpg?fp-x=.52&fp-y=.57&fit=crop&crop=focalpoint&w=581&h=500'}
          desktopImg={'https://leatherspa.imgix.net/covid1.jpg?fit=crop&w=960&h=816&fp-x=0.5&fp-y=.56&crop=focalpoint'}
        >
          <>
            <h2>To our Leather Spa community,</h2>
            <p>
              We&apos;ve truly missed seing you in store and hope that during such a challenging time, this note finds you and your loved ones healthy and safe.
            </p>
            <HighlightText text="We're now beginning to slowly open our stores, taking into account local, state, and federal guidelines." />
            <p>
              Please rest assured that we are only opening our doors once we can safely accommodate our customers and employees, as your health is our first priority. We know that going into retail will feel a little different, but we&apos;re still committed to providing a great experience.
            </p>
            <p>
              Additionally, we have enhanced our online services with a brand new website to make it even easier for you to repair your tiems from home 24/7
            </p>
            <p>
              Thank you for being a loyal customer and a valued member of our Leather Spa Community. We look forward to serving you.
            </p>
          </>
        </HeroSection>
        <section style={{ backgroundColor: scssVars.lightGray }}>
          <Container>
            <ListHeader className="pt-5 pb-3 px-lg-5">
              We look forward to seeing you again in our stores and are taking the following precautions to help keep our customer and employees safe:
            </ListHeader>
            <Row className="pb-4">
              <Col className="p-0" xs={{ size: 6, offset: 0 }} lg={{ size: 4, offset: 2 }}>
                <List>
                  <li>Sanitizing Stations</li>
                  <li>Social Distancing</li>
                  <li>Face Coverings</li>
                  <li>Enhanced Cleaning &amp; Sanitization</li>
                </List>
              </Col>
              <Col className="p-0" xs={{ size: 6, offset: 0 }} lg={{ size: 4, offset: 2 }}>
                <List>
                  <li>Floor Markers</li>
                  <li>Safety Partitions</li>
                  <li>Protective Equipment</li>
                  <li>Staying at Home When Sick</li>
                  <li>Limited Hours</li>
                </List>
              </Col>
            </Row>
          </Container>
        </section>
        <Container>
          <Row className="py-5">
            <Col className="px-5" xs={{ size: 12, order: 1 }} lg={{ size: 4, order: 2 }}>
              <ListHeader className="text-center">Stores Open</ListHeader>
              <List style={{ paddingLeft: '5rem' }}>
                <li>55 West 55th Street</li>
                <li>Saks Fifth Avenue</li>
                <li>Grand Central Terminal<br />(Graybar Passage)</li>
                <li>28 John Street</li>
              </List>
            </Col>
            <Col className="px-5" xs={{ size: 12, order: 2 }} lg={{ size: 4, order: 3 }}>
              <ListHeader className="text-center pt-4 pt-lg-0">Stores Temporarily Closed</ListHeader>
              <List style={{ paddingLeft: '5rem' }}>
                <li>The Plaza Hotel</li>
                <li>Grand Central Terminal<br />(Lower Level)</li>
              </List>
            </Col>
            <Col xs={{ size: 12, order: 3 }} lg={{ size: 4, order: 1 }}>
              <ListHeader className="text-center pt-4 pt-lg-0">Start A Mail-In Or Pickup Order</ListHeader>
              <StyledLink to="/start-a-repair" className="btn btn-primary d-block">Start a Repair</StyledLink>
            </Col>
          </Row>
        </Container>
        <section style={{ backgroundColor: scssVars.lightGray, fontFamily: 'Montserrat, sans-serif', textTransform: 'uppercase' }}>
          <Container className="text-center py-5">
            <Row>
              <Col>
                <img src="https://leatherspa.imgix.net/Repair-Wizard/Icons/28_Van_Transparent@3x.png?w=125&h=125&fit=fill&fill=solid" alt="delivery van" />
                <p className="pt-3">Delivery Service</p>
              </Col>
              <Col>
                <img src="https://leatherspa.imgix.net/Repair-Wizard/Icons/35_Pick-up2_Transparent.png?fit=crop&crop=focalpoint&w=125&h=125&fp-x=.3&fp-y=.43&fp-z=1.75" alt="pick up service" />
                <p className="pt-3">On Demand Pickup</p>
              </Col>
              <Col>
                <img src="https://leatherspa.imgix.net/Repair-Wizard/Icons/27_Box_Transparent@3x.png?w=125" alt="mail-in box" />
                <p className="pt-3">Mail-In</p>
              </Col>
              <Col>
                <img src="https://leatherspa.imgix.net/Repair-Wizard/Icons/31_Customer-Services_Transparent.png?h=125&fit=crop&fp-x=0.55&w=125&crop=focalpoint&fp-z=1.35" alt="customer service" />
                <p className="pt-3">Virtual Consultation</p>
              </Col>
              <Col>
                <img src="https://leatherspa.imgix.net/Repair-Wizard/Icons/29_Map_Transparent@3x.png?w=125&h=125&fit=crop" alt="drop-off map" />
                <p className="pt-3">Express Drop-Off</p>
              </Col>
            </Row>
          </Container>
        </section>
        <Container>
          <Content className="my-5">
            <h3>FAQs</h3>
            <hr />
            <h4>Are Leather Spa stores still closed? When will they open?</h4>
            <ul>
              <li>We’re starting to reopen stores in select locations, in
            accordance with state and federal guidelines. In order to
            prioritize the health and safety of our employees and
            customers, we have put new protocols in place to increase
            cleaning and maintain social distancing. We’ve also made
            changes to our in-store services, and we have enhanced our
            online features and services to serve you better while you are
            at home.</li>
            </ul>
            <h4>What stores are open? Are there any changes to store hours?</h4>
            <ul>
              <li>
              We may be operating on alternative or shorter hours based on
              local legal requirements and business conditions. Visit our
              store locator page to check on the status of a store near you.
              </li>
            </ul>
            <h4>What precautions will Leather Spa take in stores?</h4>
            <ul>
              <li>
                <strong>Social distancing:&nbsp;</strong>
                we’re practicing social distancing. Please help us by staying six feet away from other shoppers.
              </li>
              <li>
                <strong>Limiting store capacity:&nbsp;</strong>
                due to recent regulations and to ensure enough space for
                social distancing, we’re only allowing a certain number of
                people in each store at one time, which means you may have to
                wait to enter. We really appreciate your patience.
              </li>
              <li>
                <strong>Limiting store hours:&nbsp;</strong>
                many of our stores will have updated hours to allow us extra time to sanitize everything properly.
              </li>
              <li>
                <strong>Wearing face masks:&nbsp;</strong>
                we’re wearing masks and we recommend you wear one too—for your own health and safety, as well as the safety of others.
              </li>
            </ul>
          </Content>
        </Container>
      </Main>
    </Layout>
  )
}

export default CovidUpdates
